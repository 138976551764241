html, body{
  color: #000;
  background-color: #fafafa;
  font-family: 'Special Elite', cursive;
}

#page{ min-height: 80vh; }

h1{
  font-size: 2.2rem;
}

strong{
  font-weight: 600;
}

header{
  min-height: 200px;
  padding: 50px;
  text-align: center;
}

a{ color: #000; }

a:hover{
  color: #E3DC16;
  transition: all 0.5s ease;
}

.card-mask{
  color: #fff;
}
.card-mask:hover{
  color: #E3DC16;
}

.shadow{
  box-shadow: 0 4px 8px 0 rgba(255, 129, 0, 0.2), 0 6px 20px 0 rgba(255, 129, 0, 0.2);
}

.red{ color: #ff2300; }
.orange{ color: #ff8100; }
.light{ color: #ffd2a4; }
.yellow{ color: #E3DC16; }

.back-to-home{
  z-index: 1000;
  background-color: rgb(255, 255, 255, 0.8);
  border-radius: 1px;
  position: fixed;
  top: 50px;
  left: 20px;
  transition: all 0.5s ease;
  font-size: 3em;
  height: 50px;
  width: 50px;
}

.back-to-home a{
  padding: 0;
  margin: 5px;
  transform: translate(4px, -22px);

}
.back-to-home:hover{
  transition: all 0.5s ease;
}
.text-shadow{
  text-shadow: 1px 1px 2px rgb(24, 24, 24, 0.8);
}

.social-icon{
  margin: 10px;
  transition: all 0.5s ease;
}
.social-icon:hover{
  transform: scale(1.2);
  transition: all 0.5s ease;
}
